import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  // Landing
  { path: '', loadChildren: () => import('./components/pages/agentdetails/agentdetails.module').then(m => m.AgentdetailsModule), data: { breadcrumb: '' } },
  // Pages
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'About Us' } },
  { path: 'error', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: 'Error 404' } },
  { path: 'login', loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule), data: { breadcrumb: 'Login' } },
  { path: 'register', loadChildren: () => import('./components/pages/register/register.module').then(m => m.RegisterModule), data: { breadcrumb: 'Register' } },
  { path: 'legal', loadChildren: () => import('./components/pages/legal/legal.module').then(m => m.LegalModule), data: { breadcrumb: 'Legal' } },
  // Listings
  { path: 'listing-map', loadChildren: () => import('./components/pages/listingmap/listingmap.module').then(m => m.ListingmapModule), data: { breadcrumb: 'Listing Map' } },
  { path: 'submit-listing', loadChildren: () => import('./components/pages/submitlisting/submitlisting.module').then(m => m.SubmitlistingModule), data: { breadcrumb: 'Submit Listings' } },
  // Agents
  { path: 'agent-details/:id', loadChildren: () => import('./components/pages/agentdetails/agentdetails.module').then(m => m.AgentdetailsModule), data: { breadcrumb: 'Agent Details' } },
  { path: 'profile', loadChildren: () => import('./components/pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
